<script>
import VMessages from 'vuetify/lib/components/VMessages/VMessages.js';
import VInput from 'vuetify/lib/components/VInput/VInput.js';
import {getImage} from "@/services/helpers";
const propName = "value", eventName = "input"
export default {
  name: "InputSelectRadio",
  components: { VMessages },
  extends: VInput,
  model: {
    prop: propName,
    event: eventName
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    itemText: {
      type: String,
      default: "content",
    },
    itemValue: {
      type: String,
      default: "optionId",
    },
    [propName]: {
      validator: (v) => Array.isArray(v) || typeof v === 'string'|| typeof v === 'number' || v == null,
      required: true
    },
    multiple: {
      type: Boolean,
    },
    fieldId: {
      type: String,
      required: true,
    },
    defaultValue: {
      type: String,
      default: "",
    },
    savedValue: {
      validator: (v) => Array.isArray(v) || typeof v === 'string'|| typeof v === 'number' || v == null,
    },
    img: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
    },
    multipleForms: {
      type: Boolean,
    },
    multipleFormsIndex: {
      type: Number,
      default: 0,
    },
  },
  data: function () {
    return {
      showErrorMessage: false,
      validateCalled: 0,
    }
  },
  computed: {
    model: {
      get() {
        return this[propName]
      },
      set(payload) {
        this.showErrorMessage = false
        this.$emit(eventName, {
          fieldId: this.fieldId,
          value: payload
        })
      }
    },
    checkboxes() {
      return this.options.map((option) => Array.isArray(this.model) ? this.model.includes(option[this.itemValue]) : this.model===option[this.itemValue])
    },
    errorMessage() {
      const rule = `pages.application.rules.required-select${this.multiple ? '-multiple' : ''}`
      return this.showErrorMessage ? [this.$t(rule)] : []
    }
  },
  created() {
    let value
    if (!!this.savedValue && this.savedValue !== "None") {
      value = this.savedValue
    } else if (this.defaultValue) {
      value = this.defaultValue
    }
    if (value)
      this.model = this.multipleForms ? value[this.multipleFormsIndex] : value
  },
  methods: {
    getImage,
    selectBtn(index) {
      const value = this.options[index][this.itemValue]
      if (this.multiple) {
        if (Array.isArray(this.model)) {
          const index = this.model.indexOf(value)

          let arr = this.model.slice()
          if (index === -1) arr.push(value)
          else arr.splice(index, 1)
          this.model = arr

        } else this.model = [value]
      } else {
        if (this.model === value) this.model = null
        else this.model = value
      }
    },
    isItemSelected(index) {
      const value = this.options[index][this.itemValue]
      if (this.multiple) {
        return Array.isArray(this.model) && this.model.indexOf(value) !== -1
      } else return this.model === value
    },
    checkIfValid() {
      return Array.isArray(this.model) ? this.model.length > 0 : !!this.model
    },
    validate() {
      this.validateCalled++
      if (this.validateCalled===1) return
      if (this.required) {
        const valid = this.checkIfValid()
        if (!valid) this.showErrorMessage = true
        return valid
      }
      return true
    },
  }
}
</script>

<template>
  <div class="mb-7 wrapper">
    <v-img
      v-if="img"
      :src="getImage(img)"
      class="mb-4"
      contain
    />
    <v-checkbox
      v-for="(item,i) in options"
      :key="`select-radio-${i}`"
      v-model="checkboxes[i]"
      hide-details
      :label="item[itemText]"
      :class="{
        'mb-2': i !== options.length - 1,
        'selected': isItemSelected(i),
        'error-state': showErrorMessage
      }"
      @click="selectBtn(i)"
    />
    <VMessages
      :value="errorMessage"
      color="error"
      style="margin: 8px 12px"
    />
  </div>
</template>

<style scoped lang="scss">
.wrapper {
  width: 100%;
}
.error-state::v-deep {
  .v-input__slot {
    .v-icon {
      color: var(--v-error-base) !important;
    }
  }
}
.button::v-deep {
  border-radius: 10px;
  .v-btn__content {
    color: black;
  }
}
</style>